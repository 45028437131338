<template>
  <main role="main" class="main bg-navy">
    <div class="bg-navy d-block d-md-none">
      <b-row class="no-gutters">
        <b-col class="text-center">
          <b-button type="button" variant="link" class="color-white float-left py-3" to="/">
            <feather type="arrow-left"></feather>
          </b-button>
          <span class="d-inline-block h-100 title-w-60">
            <span class="d-table h-100">
              <span class="d-table-cell align-middle">
                <strong class="color-white">Payments</strong>
              </span>
            </span>
          </span>
        </b-col>
      </b-row>
    </div>
    <div class="mobile-vh min-h-100 p-4 p-md-5 bg-white mobi-rounded-top">
      <b-row class="custom-height">
        <b-col lg="10" offset-lg="1">
          <h2 class="mobile-size-reduce color-navy-blue mb-0">Truzo payments</h2>
          <p class="text-uppercase mb-2 mb-md-4">All your pending payments are listed below.</p>
          <div class="h-md-60" v-if="links && links.length > 0">
            <h5 class="mb-0">Your preferred payment method</h5>
            <p>Saved details that can be used for this payment. You can <a href="#" @click.prevent="clearPreference()">clear these details</a> at any time.</p>
            <ul class="list-unstyled row mx-n1 mx-md-n3 transaction-select">
              <li class="col-12 px-1 px-md-3 mb-3 mb-md-0">
                <a class="disabled mb-1 mb-md-3 box" tabindex="-1" v-if="!preferred || !preferred.type">
                  <b-row>
                    <b-col cols="4" md="2">
                      <div class="icon mb-0">
                        <font-awesome-icon icon="battery-empty" size="3x" class="mt-3"></font-awesome-icon>
                      </div>
                    </b-col>
                    <b-col cols="8" md="3" class="text-left">
                      <p class="mt-1 mb-2"><strong>None saved</strong></p>
                      <strong></strong><br />
                      <p class="line-height-1 mb-0"><small><br /><br /></small></p>
                    </b-col>
                    <b-col cols="12" md="7" class="d-none d-md-block text-right my-auto">
                      <p class="my-auto">To save securely any of the payment methods to be used later, choose a method with your next payment, then select "Save as preferred payment method" before completing.</p>
                    </b-col>
                    <b-col cols="12" md="7" class="d-block d-md-none text-center mt-3">
                      <p class="mb-0">You can save securely any of the payment methods, including specific payment details, as your preferred method to use later.</p>
                    </b-col>
                  </b-row>
                </a>
                <a class="disabled mb-1 mb-md-3 box" tabindex="-1" v-else-if="methodIndex === -1">
                  <b-row>
                    <b-col cols="4" md="2">
                      <div class="icon mb-0">
                        <font-awesome-icon :icon="getTypeIcon(preferred.type)" size="3x" class="mt-3"></font-awesome-icon>
                      </div>
                    </b-col>
                    <b-col cols="8" md="3" class="text-left">
                      <p class="mt-1 mb-2"><strong>{{ getTypeName(preferred.type) }}</strong></p>
                    </b-col>
                    <b-col cols="12" md="7" class="d-none d-md-block text-right my-auto" v-html="preferredDescription"></b-col>
                    <b-col cols="12" md="7" class="d-block d-md-none text-center mt-3" v-html="preferredDescription"></b-col>
                  </b-row>
                </a>
                <a class="disabled mb-1 mb-md-3 box" tabindex="-1" v-else-if="(getTypeName(preferred.fee.method) === 'Card' && (amount + (preferred.fee.fee * buyersPercentage * 1.15 / 100)) >= 100000) || (getTypeName(preferred.fee.method) === 'Truzo wallet' && (walletAvailable && (amount + (preferred.fee.fee * buyersPercentage * 1.15 / 100)) >= walletAvailable || !walletAvailable))">
                  <b-row>
                    <b-col cols="4" md="2">
                      <div class="icon mb-0">
                        <font-awesome-icon :icon="getTypeIcon(preferred.fee.method)" size="3x" class="mt-3"></font-awesome-icon>
                      </div>
                    </b-col>
                    <b-col cols="8" md="3" class="text-left">
                      <p class="mt-1 mb-2"><strong>{{ getTypeName(preferred.fee.method) }}</strong></p>
                      <strong>Unavailable</strong><br />
                      <p class="line-height-1 mb-0"><small><br /><br /></small></p>
                    </b-col>
                    <b-col cols="12" md="7" class="d-none d-md-block text-right my-auto" v-html="preferredDescription"></b-col>
                    <b-col cols="12" md="7" class="d-block d-md-none text-center mt-3" v-html="preferredDescription"></b-col>
                  </b-row>
                </a>
                <a class="disabled mb-1 mb-md-3 box" v-else>
                  <b-row>
                    <b-col cols="4" md="2">
                      <div class="icon mb-0">
                        <font-awesome-icon :icon="getTypeIcon(preferred.fee.method)" size="3x" class="mt-3"></font-awesome-icon>
                      </div>
                    </b-col>
                    <b-col cols="8" md="3" class="text-left">
                      <p class="mt-1 mb-2"><strong>{{ getTypeName(preferred.fee.method) }}</strong></p>
                      <strong>{{ amount + preferred.fee.fee * buyersPercentage * 1.15 / 100 | formatAmount(currency) }}</strong><br />
                      <p class="line-height-1 mb-0"><small>Fee: {{ parseFloat(preferred.fee.fee * buyersPercentage / 100) | formatAmount(currency) }}<br />Vat: {{ preferred.fee.fee * buyersPercentage / 100 * 0.15 | formatAmount(currency) }}</small></p>
                    </b-col>
                    <b-col cols="12" md="7" class="d-none d-md-block text-right my-auto" v-html="preferredDescription"></b-col>
                    <b-col cols="12" md="7" class="d-block d-md-none text-center mt-3" v-html="preferredDescription"></b-col>
                  </b-row>
                </a>
              </li>
            </ul>
            <h5 class="mt-3 mb-0">Pending payments</h5>
            <p v-if="preferred">You can view the payment details and select a specific payment method, or pay using your preferred payment method.</p>
            <p v-else>You can view the payment details, then select a specific payment method.</p>
            <ul class="list-unstyled row mx-n1 mx-md-n3 transaction-select">
              <li class="col-12 px-1 px-md-3 mb-3 mb-md-0" v-for="(record, $index) in links" :key="$index">
                <a href="#" class="mb-1 mb-md-3 box" @click.prevent="methodIndex = $index" :class="methodIndex === $index ? 'active' : ''">
                  <b-row>
                    <b-col cols="4" md="2" class="my-auto">
                      <div class="icon mb-0">
                        <font-awesome-icon :icon="getIcon(record)" size="3x" class="mt-3"></font-awesome-icon>
                      </div>
                    </b-col>
                    <b-col cols="8" md="10" class="text-left">
                      <b-row>
                        <b-col cols="12" class="text-left">
                          <p class="mt-1 mb-2"><strong>{{ getName(record) }}</strong></p>
                        </b-col>
                        <b-col cols="12" md="6" v-if="getAmount(record)">
                          <strong>{{ getAmount(record) | formatAmount(getCurrency(record)) }}</strong><br />
                          <p class="line-height-1 mb-0" v-if="getFees(record)">
                            <small>
                              Fees: {{ getFees(record)[0] | formatAmount(getCurrency(record)) }} - {{ getFees(record)[1] | formatAmount(getCurrency(record)) }}<br />
                              Payable: {{ getTotal(record)[0] | formatAmount(getCurrency(record)) }} - {{ getTotal(record)[1] | formatAmount(getCurrency(record)) }}
                            </small>
                          </p>
                        </b-col>
                        <b-col md="6" class="d-none d-md-block text-right">
                          <p class="mb-0 line-height-125" v-html="getDescription(record)"></p>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="12" class="d-block d-md-none text-center mt-3">
                      <p class="mb-0 line-height-125" v-html="getMobileDescription(record)"></p>
                    </b-col>
                  </b-row>
                </a>
              </li>
            </ul>
          </div>
          <b-form-group class="btn-bottom" v-if="links && links.length > 0">
            <b-row class="mt-xl-4">
              <b-col md="6" order-md="2">
                <b-button class="mt-3" variant="custom" block pill :disabled="methodIndex === -1" @click.prevent="next()">
                  Next
                </b-button>
              </b-col>
              <b-col md="6">
                <loading-button :replace="actionStatus">
                  <b-button variant="outline-custom" pill block class="mt-3" href="#" :disabled="methodIndex === -1 || !preferred || !preferred.type || (getTypeName(preferred.fee.method) === 'Card' && (amount + (preferred.fee.fee * buyersPercentage * 1.15 / 100)) >= 100000) || (getTypeName(preferred.fee.method) === 'Truzo wallet' && (walletAvailable && (amount + (preferred.fee.fee * buyersPercentage * 1.15 / 100)) >= walletAvailable || !walletAvailable))" @click.prevent="complete()">
                    Pay with preferred
                  </b-button>
                </loading-button>
              </b-col>
            </b-row>
          </b-form-group>
          <div class="h-md-60" v-else>
            <b-form-group class="text-center v-center">
              <span class="indicator bg-success">
                <div class="d-table h-100 w-100">
                  <div class="d-table-cell align-middle">
                    <feather type="check"></feather>
                  </div>
                </div>
              </span>
              <h5 class="color-navy-blue mb-0">Woohoo! There're no pending payments linked to you.</h5>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </div>
    <stitch-fund-modal ref="stitch_modal" :url="ozowLink" :id="transaction ? transaction._id : null" :reference="transaction ? transaction.reference : null" :hash="stitchHash" :status="paymentStatus === 'paid'" v-if="preferred && preferred.type === 'instanteft'"></stitch-fund-modal>
    <secure-modal ref="3dsecure_comp2" :card="preferred.details._id" :transaction="transaction" :secure="paymentStatus === '3dsecure' ? payments.data : { payload: null, url: null, md: null }" v-if="preferred && preferred.type === 'card'"></secure-modal>
    <!-- <secure-modal ref="3dsecure_fnb" :transaction="transaction" :secure="paymentStatus === '3dsecure' ? payments.data : { txnToken: null, url: null }" v-if="$route.params.method === 'card'"></secure-modal> -->
    <b-modal id="complete_payment" size="lg" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <div>
        <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('complete_payment')">×</button>
        <h4 class="font-weight-400 mt-2 mb-3">Complete payment</h4>
        <p>You're about to complete the payment for transaction {{ transaction ? transaction.reference : 'unknown' }}<span class="font-20-bigger">{{ transaction ? ', ' + transaction.name : null }}</span>.</p>
        <p>The following actions will occur once the payment is completed:</p>
        <ul>
          <li><b>{{ userName }}</b> will be confirmed as a buyer for this transaction, and the transaction will be approved on your behalf.</li>
          <li>Total of <b>{{ transaction ? formatCurrency(transaction.amounts.amount + correspondingFee, transaction.amounts.currency, 2) : 'The funds' }}</b> will be debited from your <b>{{ debitFrom }}</b>.</li>
          <li><b>{{ transaction ? formatCurrency(transaction.amounts.amount, transaction.amounts.currency, 2) : 'The funds' }}</b> will be allocated to this transaction.</li>
          <li v-if="correspondingFee > 0">Corresponding Truzo fees of <b>{{ correspondingFee | formatAmount(transaction.amounts.currency, 2) }}</b> will be debited immediately. Please, refer to our <a href="https://truzo.com/terms-and-conditions/" target="_blank">Terms & Conditions <font-awesome-icon icon="link"></font-awesome-icon></a> for more details on Truzo fee allocation.</li>
          <li>The seller will be notified via their preferred communication channel and advised to start the delivery{{ transaction ? ' of ' : null }}<b>{{ transaction ? transaction.name : null }}</b>.</li>
          <li v-if="saveDetails">This payment method and corresponding details will be saved as your preferred method, and you will be able to fund your transactions or <b>Truzo wallet</b> in the future via a <b>single click payment</b> option.</li>
          <li v-if="dontShow">This confirmation prompt <b>will not be shown</b> to you on your next payment.</li>
        </ul>
        <b-row>
          <b-col cols="12">
            <b-form-group class="mb-0">
              <maz-checkbox v-model="dontShow">Do not show this again.</maz-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="12" md="6" order-md="2">
            <loading-button :replace="actionStatus">
              <b-button variant="custom" pill block class="mt-3" @click.prevent="doPayment()">Complete payment</b-button>
            </loading-button>
          </b-col>
          <b-col cols="12" md="6">
            <b-button variant="outline-custom" pill block class="mt-3" @click.prevent="$bvModal.hide('complete_payment')">
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal id="payment_error" size="md" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <div>
        <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('payment_error')">×</button>
        <h4 class="font-weight-400 mt-2 mb-0 text-center">Payment error</h4>
        <b-row>
          <b-col class="text-center">
            <feather type="alert-circle" size="10rem" stroke="#dc3545" class="my-3"></feather>
            <p v-if="alert.message"><b>{{ alert.message }}</b></p>
            <p v-else><b>There was an error processing your payment.</b></p>
          </b-col>
        </b-row>
        <p>Please, review the error and select one of the following available actions:</p>
        <ul>
          <li><a href="#" @click.prevent="retry()">Retry the payment</a>.</li>
          <li><a href="#" @click.prevent="$bvModal.hide('payment_error')">Verify the payment details</a> and then try the payment again.</li>
          <li>Select a <a href="#" @click.prevent="other()">different payment method</a>.</li>
          <li>Contact Truzo support via <a href="https://truzo.com/support/" target="_blank">our ticketing system</a>.</li>
        </ul>
        <b-row class="mb-2">
          <b-col cols="12" md="6" offset-md="6">
            <b-button variant="outline-custom" pill block class="mt-3" @click.prevent="$bvModal.hide('payment_error')">
              Close
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </main>
</template>

<script>
import randomstring from 'randomstring'
import { paymentService, transactionService } from '@/services'

import SecureModal from '@/components/modals/3DSecureModalExisting'
import StitchFundModal from '@/components/modals/StitchFundModal'

import { mapActions, mapState } from 'vuex'

export default {
  components: {
    SecureModal,
    StitchFundModal
  },
  data () {
    return {
      links: [],
      dontShow: false,
      saveDetails: false,
      methodIndex: -1,
      templates: [
        { value: 'Card', name: 'Card', icon: ['far', 'credit-card'], description: 'We accept all Credit, Debit, Cheque and Hybrid cards issued by MasterCard&reg; and Visa&trade;.' },
        { value: 'EFT', name: 'Manual EFT', icon: 'pen-alt', description: 'We have trust accounts with ' },
        { value: 'InstantEFT', name: 'Instant EFT', icon: 'stopwatch', description: 'We\'ve integrated with <a href="https://ozow.com" target="_blank">Ozow</a> and <a href="https://stitch.money" target="_blank">Stitch</a> to facilitate secure and efficient Instant EFT payments on our platform.' },
        { value: 'Wallet', name: 'Truzo wallet', icon: 'wallet', description: '<b>Truzo wallet</b> is an easy, secure and affordable way to transact online and offline.' },
        { value: 'PayPal', name: 'PayPal', icon: 'paypal' }
      ],
      walletDescription: '<b>Truzo wallet</b> is an easy, secure and affordable way to transact online and offline.',
      ozowLink: null,
      stitchHash: null
    }
  },
  created () {
    if (this.loggedIn) {
      if (!this.user.status !== 'loaded') {
        this.getUser()

        if (this.status.user.user.company) {
          this.getCompany()
        }
      }
    } else {
      this.$router.push('/login')
    }
  },
  mounted () {
    if (this.loggedIn) {
      const _enabled = localStorage.getItem('paymentconfirmation')
      if (_enabled === 'disabled') {
        this.dontShow = true
      }

      paymentService.getAll()
        .then(
          links => {
            if (links && links.length > 0) {
              this.links = links
              this.getWallet(this.currency)
            }
          },
          error => {
            console.log(error)
          }
        )
    } else {
      this.$router.push('/login')
    }
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      banks: state => state.banks.StitchBanks,
      company: state => state.company,
      payment: state => state.link,
      payments: state => state.payments,
      record: state => state.transaction,
      status: state => state.auth,
      transaction: state => state.transaction.transaction,
      txnActions: state => state.txnActions,
      user: state => state.user,
      wallet: state => state.wallet
    }),
    loggedIn () {
      return this.status.status.loggedIn
    },
    loadStatus () {
      return this.record.status
    },
    paymentStatus () {
      return this.payments.status
    },
    txnActionStatus () {
      return this.txnActions.status
    },
    actionStatus () {
      return this.paymentStatus === 'paying' || this.paymentStatus === 'loading' || this.paymentStatus === '3dsecure' || this.txnActionStatus === 'saving'
    },
    userName () {
      if (this.status.user && this.status.user.user) {
        if (this.status.user.user.company) {
          return this.status.user.user.company_name
        } else {
          return this.status.user.user.name
        }
      } else {
        return 'You'
      }
    },
    correspondingFee () {
      if (this.methodIndex >= 0 && this.preferred) {
        const _link = this.links[this.methodIndex]
        if (_link.transaction && _link.transaction._id) {
          if (this.buyersPercentage && this.buyersPercentage > 0) {
            const _fee = _link.transaction.amounts.fees.find(_fee => _fee.method.name.toLowerCase() === this.preferred.type)
            if (_fee) {
              return _fee.fee * this.buyersPercentage / 100 * 1.15
            } else {
              return 0
            }
          } else {
            return 0
          }
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    linkid () {
      if (this.payment && this.payment.type === 'payment' && this.payment.id) {
        return this.payment.id
      } else {
        return false
      }
    },
    fees () {
      if (this.methodIndex >= 0) {
        const _link = this.links[this.methodIndex]
        if (_link.transaction && _link.transaction._id) {
          const _amounts = _link.transaction.amounts
          const _split = _amounts.fee_payable_by.buyer_percentage / 100
          const _minimum = Math.min.apply(Math, _amounts.fees.map(function(o) { return o.fee })) * _split * 1.15
          const _maximum = Math.max.apply(Math, _amounts.fees.map(function(o) { return o.fee })) * _split * 1.15
          return [_minimum, _maximum]
        } else {
          return false
        }
      } else {
        return false
      }
    },
    commission () {
      if (this.methodIndex >= 0) {
        const _link = this.links[this.methodIndex]
        if (_link.transaction && _link.transaction._id) {
          const _amounts = _link.transaction.amounts
          const _split = _amounts.split.find(_record => _record.actor === 'Buyer')
          if (_split && _split.agent_comm && _split.agent_comm.length > 0) {
            return _split.agent_comm[0].fee
          } else {
            return 0
          }
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    total () {
      if (this.methodIndex >= 0) {
        const _link = this.links[this.methodIndex]
        if (_link.transaction && _link.transaction._id) {
          const _amounts = _link.transaction.amounts
          const _total = this.commission + _amounts.amount
          return [_total + this.fees[0], _total + this.fees[1]]
        } else {
          return false
        }
      } else {
        return false
      }
    },
    amount () {
      if (this.methodIndex >= 0) {
        const _link = this.links[this.methodIndex]
        if (_link.transaction && _link.transaction._id) {
          const _amounts = _link.transaction.amounts
          const _total = this.commission + _amounts.amount
          return _total
        } else {
          return false
        }
      } else {
        return false
      }
    },
    buyersPercentage () {
      if (this.methodIndex >= 0) {
        const _link = this.links[this.methodIndex]
        if (_link.transaction && _link.transaction._id) {
          const _amounts = _link.transaction.amounts
          return _amounts.fee_payable_by.buyer_percentage
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    preferred () {
      if (this.loggedIn && this.user.status === 'loaded') {
        let _preferred = null
        let _cards = null

        if (this.status.user.user.company) {
          _preferred = this.company && this.company.company ? this.company.company.preferred : null
          _cards = this.company && this.company.company ? this.company.company.cards : null
        } else {
          _preferred = this.user && this.user.user ? this.user.user.preferred : null
          _cards = this.user && this.user.user ? this.user.user.cards : null
        }

        if (_preferred && _preferred.type) {
          let _fee = null
          if (this.methodIndex >= 0) {
            const _payment = this.links[this.methodIndex]
            if (_payment.transaction && _payment.transaction._id) {
              const _fees = _payment.transaction.amounts ? _payment.transaction.amounts.fees : []
              if (_preferred && _fees && _fees.length > 0) {
                _fee = _fees.find(_fee => _fee.method.name.toLowerCase() === _preferred.type)
              }
            } else if (_payment.wallet_fund && _payment.wallet_fund._id) {
              _fee = null
            }
          }

          if (_preferred.type === 'card' && _cards && _cards.length > 0) {
            const _card = _cards.find(_card => _card._id === _preferred.details.id)
            if (_card) {
              return {
                type: 'card',
                details: _card,
                fee: _fee
              }
            } else {
              return null
            }
          } else {
            return {
              type: _preferred.type,
              details: _preferred.details,
              fee: _fee
            }
          }
        } else {
          return null
        }
      } else {
        return null
      }
    },
    preferredDescription () {
      if (this.preferred) {
        if (this.preferred.type === 'card') {
          const _card = this.preferred.details
          let _description = '<p class="mb-1"><b>' + _card.card_name + ', ' + _card.card_number + ', ' + _card.expiry_date + '</b></p>'
          _description += '<p class="mb-0"><b>Please note:</b> You may still be asked to verify your payment either by entering your CVC or via 3D Secure verification process.'
          return _description
        } else if (this.preferred.type === 'instanteft') {
          const _account = this.preferred.details.account
          if (_account) {
            const _bank = this.banks.find(_bank => _bank.name === _account.bankId)
            let _description = '<p class="mb-1"><b>' + (_bank ? _bank.truzo + '<br />' : null) + _account.name + ', ' + _account.accountNumber + '</b></p>'
            _description += '<p class="mb-0"><b>Please note:</b> You may still be asked to verify your payment via your bank\'s multifactor challenge.'
            return _description
          } else {
            return this.getTypeDescription(this.preferred.type)
          }
        } else {
          return this.getTypeDescription(this.preferred.type)
        }
      } else {
        return null
      }
    },
    selected () {
      if (this.methodIndex >= 0) {
        if (this.link && this.link.transaction && this.link.transaction._id) {
          return this.link.transaction.amounts.fees[this.methodIndex].method.name
        } else {
          return 'Unknown'
        }
      } else if (this.methodIndex === -1 && this.preferred) {
        if ((this.preferred.type === 'card' && (this.amount + (this.preferred.fee.fee * this.buyersPercentage * 1.15 / 100)) >= 100000) || (this.preferred.type === 'wallet' && (this.walletAvailable && (this.amount + (this.preferred.fee.fee * this.buyersPercentage * 1.15 / 100)) >= this.walletAvailable || !this.walletAvailable))) {
          return 'Unknown'
        } else {
          return 'Preferred'
        }
      } else {
        return 'Unknown'
      }
    },
    debitFrom () {
      let _method = null
      if (this.methodIndex >= 0 && this.preferred) {
        const _transaction = this.links[this.methodIndex].transaction
        if (_transaction && _transaction._id) {
          _method = _transaction.amounts.fees.find(_method => _method.method.name === this.preferred.fee.method.name)
        }
      }

      if (_method && _method.method) {
        _method = _method.method.name
        if (_method === 'Card') {
          if (this.methodIndex === -1 && this.preferred && this.preferred.details) {
            return 'Card, number ' + this.preferred.details.card_number + ', expiring on ' + this.preferred.details.expiry_date
          } else {
            return 'Credit / Debit card'
          }
        } else if (_method === 'Wallet') {
          return 'Truzo wallet'
        } else if (_method === 'EFT') {
          return 'Bank account selected via your EFT transfer'
        } else if (_method === 'InstantEFT') {
          const _account = this.preferred.details.account
          if (_account) {
            return _account.name + ', ' + _account.accountNumber
          } else {
            return 'Bank account selected via your Instant EFT transfer'
          }
        } else {
          return 'chosen payment method'
        }
      } else {
        return 'chosen payment method'
      }
    },
    currency () {
      if (this.methodIndex >= 0) {
        const _link = this.links[this.methodIndex]
        if (_link.transaction && _link.transaction._id) {
          return _link.transaction.amounts.currency
        } else if (_link.wallet_fund && _link.wallet_fund._id) {
          return _link.wallet_fund.currency
        } else {
          return 'ZAR'
        }
      } else {
        return 'ZAR'
      }
    },
    transaction () {
      if (this.methodIndex >= 0) {
        const _link = this.links[this.methodIndex]
        if (_link && _link.transaction && _link.transaction._id) {
          return _link.transaction
        } else {
          return null
        }
      } else {
        return null
      }
    },
    walletStatus () {
      return this.wallet[this.currency].status
    },
    walletAvailable () {
      if (this.methodIndex >= 0) {
        if (this.wallet[this.currency].status === 'loaded') {
          return this.wallet[this.currency].wallet.available
        } else {
          return null
        }
      } else {
        return null
      }
    },
    canPay () {
      return this.selected === 'Preferred'
    }
  },
  methods: {
    ...mapActions('alert', {
      setError: 'error'
    }),
    ...mapActions('company', {
      getCompany: 'get'
    }),
    ...mapActions('link', ['clear', 'save']),
    ...mapActions('payments', {
      payCard: 'payCard',
      payEft: 'payEft',
      payStitch: 'payStitch',
      payWallet: 'payWallet',
      prepareTxn: 'prepareTxn'
    }),
    ...mapActions('transaction', {
      getTransaction: 'get'
    }),
    ...mapActions('txnActions', ['accept', 'action']),
    ...mapActions('user', {
      clearPreference: 'clearPreference',
      getUser: 'get'
    }),
    ...mapActions('wallet', {
      getWallet: 'get'
    }),
    next: function () {
      this.clear()
      this.save({
        type: 'payment',
        link: '/' + this.links[this.methodIndex]._id,
        id: this.links[this.methodIndex]._id
      })
      this.$router.push('/pay')
    },
    formatCurrency: function (value, currency, numDigits) {
      if (typeof value !== 'number') return value
      const _numDigits = numDigits === null || numDigits === undefined ? 2 : numDigits
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'ZAR',
        minimumFractionDigits: _numDigits,
        maximumFractionDigits: _numDigits
      })

      const _rounded = Math.round(value * 1000) / 1000
      const _value = formatter.format(_rounded).replace('ZAR', 'R')
      return _value
    },
    getTypeName: function (method) {
      return this.templates.find(template => template.value.toLowerCase() === method || template.value === method.name).name
    },
    getTypeIcon: function (method) {
      return this.templates.find(template => template.value.toLowerCase() === method || template.value === method.name).icon
    },
    getTypeDescription: function (method) {
      return this.templates.find(template => template.value.toLowerCase() === method || template.value === method.name).description
    },
    getName: function (payment) {
      if (payment.transaction && payment.transaction._id) {
        return payment.transaction.name
      } else if (payment.wallet_fund && payment.wallet_fund._id) {
        return payment.wallet_fund.reference
      } else {
        return 'Payment'
      }
    },
    getCurrency: function (payment) {
      if (payment.transaction && payment.transaction._id) {
        return payment.transaction.amounts.currency
      } else if (payment.wallet_fund && payment.wallet_fund._id) {
        return payment.wallet_fund.currency
      } else {
        return 'ZAR'
      }
    },
    getDescription: function (payment) {
      if (payment.transaction && payment.transaction._id) {
        let _description = '<small>Reference: <b>' + payment.transaction.reference + '</b><br />'
        _description += 'Date created: <b>' + this.$options.filters.moment(payment.created_at, 'D MMMM YYYY') + '</b><br />'
        _description += 'Created by: <b>' + this.$options.filters.formatUserShort(payment.created_by) + '</b></small>'
        return _description
      } else if (payment.wallet_fund && payment.wallet_fund._id) {
        return ''
      } else {
        return ''
      }
    },
    getMobileDescription: function (payment) {
      if (payment.transaction && payment.transaction._id) {
        let _description = '<small>Reference: <b>' + payment.transaction.reference + '<br />'
        _description += 'Created on <b>' + this.$options.filters.moment(payment.created_at, 'D MMMM YYYY') + '</b> '
        _description += 'By <b>' + this.$options.filters.formatUserShort(payment.created_by) + '</b></small>'
        return _description
      } else if (payment.wallet_fund && payment.wallet_fund._id) {
        return ''
      } else {
        return ''
      }
    },
    getIcon: function (payment) {
      if (payment.transaction && payment.transaction._id) {
        return 'money-check-alt'
      } else if (payment.wallet_fund && payment.wallet_fund._id) {
        return 'wallet'
      } else {
        return 'battery-empty'
      }
    },
    getCommission: function (payment) {
      if (payment.transaction && payment.transaction._id) {
        const _amounts = payment.transaction.amounts
        const _split = _amounts.split.find(_record => _record.actor === 'Buyer')
        if (_split && _split.agent_comm && _split.agent_comm.length > 0) {
          return _split.agent_comm[0].fee
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    getAmount: function (payment) {
      if (payment.transaction && payment.transaction._id) {
        const _amounts = payment.transaction.amounts
        const _total = this.getCommission(payment) + _amounts.amount
        return _total
      } else if (payment.wallet_fund && payment.wallet_fund._id) {
        return payment.wallet_fund.amount
      } else {
        return null
      }
    },
    getTotal: function (payment) {
      if (payment.transaction && payment.transaction._id) {
        const _amounts = payment.transaction.amounts
        const _total = this.getCommission(payment) + _amounts.amount
        return [_total + this.getFees(payment)[0], _total + this.getFees(payment)[1]]
      } else if (payment.wallet_fund && payment.wallet_fund._id) {
        return [payment.wallet_fund.amount, payment.wallet_fund.amount]
      } else {
        return null
      }
    },
    getPercentage: function (payment) {
      if (payment.transaction && payment.transaction._id) {
        const _amounts = payment.transaction.amounts
        return _amounts.fee_payable_by.buyer_percentage
      } else if (payment.wallet_fund && payment.wallet_fund._id) {
        return 100
      } else {
        return null
      }
    },
    getFees: function (payment) {
      if (payment.transaction && payment.transaction._id) {
        const _amounts = payment.transaction.amounts
        const _split = _amounts.fee_payable_by.buyer_percentage / 100
        const _minimum = Math.min.apply(Math, _amounts.fees.map(function(o) { return o.fee })) * _split * 1.15
        const _maximum = Math.max.apply(Math, _amounts.fees.map(function(o) { return o.fee })) * _split * 1.15
        return [_minimum, _maximum]
      } else if (payment.wallet_fund && payment.wallet_fund._id) {
        return [0, 0]
      } else {
        return null
      }
    },
    complete: function () {
      if (this.dontShow) {
        this.doPayment()
      } else {
        this.$bvModal.show('complete_payment')
      }
    },
    doPayment: function () {
      const _transaction = this.links[this.methodIndex].transaction
      if (_transaction && _transaction._id) {
        transactionService.get(_transaction._id)
          .then(
            _transaction => {
              const _link = _transaction.links.find(_link => _link.actor === 'buyer')
              if (_link) {
                this.actioning = true
                this.accept({ id: _link._id, transaction: this.transaction._id, company: this.status.user.company })
              } else {
                if (_transaction.approvals.buyer) {
                  this.payNow()
                } else {
                  this.actioning = true
                  this.action({ action: 'approve', id: this.transaction._id })
                }
              }
            },
            error => {
              console.log(error)
              this.setError('There was an error retrieving transaction details for payment. Please, try again later ...')
            }
          )
      }
    },
    payNow: function () {
      if (this.preferred.type=== 'eft') {
        this.payEft(this.transaction._id)
      } else if (this.preferred.type === 'wallet') {
        this.payWallet({
          id: this.transaction._id,
          saveDetails: this.saveDetails
        })
      } else if (this.preferred.type === 'instanteft') {
        this.stitchHash = randomstring.generate({ length: 32, charset: 'hex', capitalization: 'lowercase' })
        this.payStitch({ id: this.transaction._id, hash: this.stitchHash, forcenew: false })
      } else if (this.preferred.type === 'card') {
        // this.prepareTxn(this.transaction._id)
        this.payCard({
          id: this.transaction._id,
          card: this.preferred.details._id,
          saveDetails: this.saveDetails
        })
      }
    },
    retry: function () {
      this.$bvModal.hide('payment_error')
      this.complete()
    },
    other: function () {
      this.next()
    }
  },
  watch: {
    currency (_new, _old) {
      if (_new && _new !== _old) {
        this.getWallet(_new)
      }
    },
    txnActionStatus (_status) {
      if (_status === 'loaded' && this.actioning) {
        this.actioning = false
        this.payNow()
      }
    },
    paymentStatus: async function (_status) {
      if (_status === 'loaded') {
        this.$bvModal.hide('complete_payment')
        this.ozowLink = this.payments.data
        this.$bvModal.show('stitchfund-modal')
        this.$refs.stitch_modal.submitForm()
      }

      if (_status === 'paid') {
        if (this.dontShow) {
          localStorage.setItem('paymentconfirmation', 'disabled')
        }

        await paymentService.updateLink(this.links[this.methodIndex]._id, 'Completed')
        this.clear()

        this.$router.push('/success')
      }

      if (_status === 'failed') {
        this.$bvModal.hide('complete_payment')
        this.$bvModal.show('payment_error')
      }

      /* if (_status === '3dsecure') {
        this.$refs['3dsecure_fnb'].info = true
        this.$bvModal.show('3dsecure_fnb')
        this.$refs['3dsecure_fnb'].startPolling()
      } */
      if (_status === '3dsecure') {
        this.$bvModal.hide('complete_payment')
        this.$bvModal.show('3dsecure_existing')
        this.$refs['3dsecure_comp2'].submitForm()
      }
    },
    walletAvailable () {
      if (this.walletAvailable) {
        const _template = this.templates.find(_template => _template.value === 'Wallet')
        _template.description = this.walletDescription + ' Your current <b>Truzo wallet</b> balance is <b>' + this.formatCurrency(this.walletAvailable, this.currency, 0) + '</b>.'
      }
    },
    walletStatus (value) {
      if (value === 'loaded' && this.walletAvailable) {
        const _template = this.templates.find(_template => _template.value === 'Wallet')
        _template.description = this.walletDescription + ' Your current <b>Truzo wallet</b> balance is <b>' + this.formatCurrency(this.walletAvailable, this.currency, 0) + '</b>.'
      }
    }
  }
}
</script>
